:root {
  --amazonOrange: #1F8ADD
}
body {
  background: #EEE;
}

html, #root, body, .App, .App>div {
  height: 100%
}

.loginForm {
  font-size: 500px;
}

.SignInLogo {
  width: 170px;
  margin-bottom:24px;
}

.formInputsDiv > .signInHeader {
  color: #555;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  margin: 24px;
  text-align: center;
}

.formInputsDiv input {
  height: 33px!important;
}

.formInputsDiv select {
  height: 33px!important;
}

.loginInput {
    box-sizing: border-box;
    height: 33px;
    width: 317px;
    border-radius: 3px;
    border: 1px solid #6EA7E9;
}

.card {
  box-sizing: border-box;
  height: 436px;
  width: 446.5px;
  border-radius: 3px;
  background-color: #FFF;
  border: 1px solid #C9C9C9;
  box-shadow: 0 1px 2px 0 rgba(87,87,87,0.5);
}

.formInputsDiv {
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
}

.formInputsDiv label {
  line-height: 18px;
  font-weight: 500;
}

.formFooterText {
  font-size: 14px;
  line-height: 16px;
  font-weight:500;
  display:table;
  margin:0 auto;
  margin-top: 39px;
  
}

.grandParentContainer{
  display:table;
  height:100%;
  margin: 0 auto;
}
.parentContainer{
display:table-cell;
vertical-align:middle;  
}
.parentContainer #loginForm{
background:#ddd;
border:1px solid #eee;
padding:10px; 
width:300px;  /*  your login form width */
}

.bgLogo{
  margin: 9px;
}

.ui.padded>.column {
  margin-top: 60px;
}

.ui.menu > .item {
  font-size: 15px;
  line-height: 20px;
}

.ui.menu > .item:not(.active) {
  color: #333;
}

.ui.menu > .item.active {
  font-weight: 700;
}

.ui.segment {
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #FFF;
  border: 1px solid #C9C9C9;
  box-shadow: 0 1px 3px 0 rgba(87,87,87,0.5);
  margin: 20px;
  padding-left:30px;
  padding-right:30px;
  padding-top: 25px;
  padding-bottom: 25px;
}


.ui.segment .ui.header {
  color: #363636;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  margin-bottom: 30px;
}

.ui.inverted.primary.button, .ui.inverted.primary.buttons .button  {
  color: #1F8ADD;
  box-shadow: 0 0 0 2px #1F8ADD inset !important;
}

.ui.inverted.primary.button:active, .ui.inverted.primary.button:focus, .ui.inverted.primary.button:hover {
  background-color: #1F8ADD;
}

.ui.breadcrumb {
  margin-inline: 20px;
  margin-block:11px;
}

.clientButtons {
  float: right;
  margin-inline: 20px!important;
}

li.MuiTreeItem-root {
  margin-top: 8px;
  margin-bottom: 8x;
  
}

.treeItem {
  font-weight: 600;
  margin-inline: 15px;

}

.treeItem>i.icon {
  font-size: 20px;
  line-height: 34px;

}

.MuiTreeItem-label:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: rgba(0, 0, 0, 0.00)!important;

}

.dropdownForm {
  width:100%
}

.dropdownForm .row {
  margin:15px;

}

.dropdownFormGrid {
  padding: 20px!important;
}

.dropdownContainer {
  float:right;
  width: 30%
}

.emptySegment {
  height:90px
}
.react-datepicker-wrapper, .react-datepicker__input-container { display: block !important; }

.content {
  width: 100%
}

.header a {
  font-size: 16px
}

/* .ui.list .list {
  padding-top: 0.3em;
  padding-bottom: 0em;
} */


.header>.headerButton {
  margin-inline: 20px;
}

.animated.button>.visible {
  width:inherit;
}


.activeUser {
  font-weight: 650;
}

.disabledUser {
  font-weight: 650;
  color: #898989;
}

.ui.small.bgLogo {
  height : 50px;
  width : auto; 
}

.folderTreeContainer {

  margin-block : 30px;

}

.newTagInput {
  margin-block : 5px;
  display: flex;
}

.ui.label.tagLabel {
  margin-block : 5px;
}

.ui.button.tagsSaveButton {
  margin-block : 5px;
}

.ui.button.center {
  display: block;
  margin: auto;
}

.archivedText {
  color: rgba(107, 107, 107, 0.87)
}

.fixedSegment {
  height: 150px;
}

.scrollableList {
  height: 100%;
  overflow: auto;
}